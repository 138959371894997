var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    ref: "form",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "action": _vm.gateway,
      "method": "post",
      "target": "frame"
    }
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "payMethod",
      "value": "card"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "id"
    },
    domProps: {
      "value": _vm.mid
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "goodsName"
    },
    domProps: {
      "value": _vm.orderName
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "currency",
      "value": "KWR"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "orderNo"
    },
    domProps: {
      "value": _vm.orderNo
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "amt"
    },
    domProps: {
      "value": _vm.paymentAmount
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "buyerName"
    },
    domProps: {
      "value": _vm.buyerName
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "buyerTel"
    },
    domProps: {
      "value": _vm.buyerPhone
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "buyerEmail"
    },
    domProps: {
      "value": _vm.buyerEmail
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "name": "returnUrl"
    },
    domProps: {
      "value": _vm.returnUrl
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "userIp",
      "value": "0:0:0:0:0:0:0:1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mallUserId",
      "value": "Korpay"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "parentEmail",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "buyerAddr",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "buyerPostNo",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mallIp",
      "value": "127.0.0.1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mallReserved",
      "value": "MallReserved"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "rcvrMsg",
      "value": "rcvrMsg"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "supplyAmt",
      "value": "0"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "vat",
      "value": "0"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "svsAmt",
      "value": "0"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "model",
      "value": "WEB"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "domain",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "transType",
      "value": "0"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "ediDate"
    },
    domProps: {
      "value": _vm.ymd
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "hashStr"
    },
    domProps: {
      "value": _vm.hash
    }
  })]), _c('div', {
    ref: "progress",
    staticClass: "progress",
    staticStyle: {
      "display": "none"
    }
  }, [_c('div', {
    staticClass: "spinner"
  })]), _c('iframe', {
    ref: "frame",
    staticStyle: {
      "position": "fixed",
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "id": "frame",
      "name": "frame",
      "src": "",
      "marginwidth": "0",
      "marginheight": "0",
      "frameborder": "no",
      "scrolling": "no"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }