<template>
<div>
    <form ref="form" :action="gateway" method="post" target="frame" style="display: none;">
        <!-- 결제수단(card, vacnt, phone) -->
        <input type="text" name="payMethod" value="card">
        <!-- 결제방법선택 -->
        <input type="text" name="id" :value="mid">
        <!-- 상품명 -->
        <input type="text" name="goodsName" :value="orderName" >
        <!-- 결제요청통화 -->
        <input type="text" name="currency" value="KWR">
        <!-- 주문번호 -->
        <input type="text" name="orderNo" :value="orderNo" >
        <!-- 결제금액 -->
        <input type="text" name="amt" :value="paymentAmount">
        <!-- 구매자명 -->
        <input type="text" name="buyerName" :value="buyerName">
        <!-- 구매자연락처 -->
        <input type="text" name="buyerTel" :value="buyerPhone">
        <!-- 구매자이메일 -->
        <input type="text" name="buyerEmail" :value="buyerEmail">
        <!-- returnUrl -->
        <input type="text" name="returnUrl" :value="returnUrl">

        <!-- 옵션 --> 
        <input type="hidden" name="userIp"	value="0:0:0:0:0:0:0:1">
            
        <input type="hidden" name="mallUserId" value="Korpay">
        <input type="hidden" name="parentEmail" value="">
        <input type="hidden" name="buyerAddr" value="">
        <input type="hidden" name="buyerPostNo" value="">
        <input type="hidden" name="mallIp" value="127.0.0.1">
        <input type="hidden" name="mallReserved" value="MallReserved"><!-- 상점 예약필드 -->
        
        <input type="hidden" name="rcvrMsg" value="rcvrMsg">
        
        <input type="hidden" name="supplyAmt" value="0">
        <input type="hidden" name="vat" value="0">
        <input type="hidden" name="svsAmt" value="0">
        <input type="hidden" name="model" value="WEB">
        <!-- <input type="hidden" name="model" value="Android"> -->
        <input type="hidden" name="domain" value="">
        
        <!-- 변경 불가능 -->
        <input type="hidden" name="transType" value="0">
        <input type="hidden" name="ediDate" :value="ymd"><!-- 전문 생성일시 -->
        <input type="hidden" name="hashStr" :value="hash"><!-- 해쉬값 -->
    </form>
    
    <div ref="progress" class="progress" style="display: none;">
        <div class="spinner"></div>
    </div>

    <iframe id="frame" ref="frame" name="frame" style="position: fixed; width:100%; height:100%;" src="" marginwidth="0" marginheight="0" frameborder="no" scrolling="no"></iframe>
</div>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
export default {
    data() {
        return {
            gateway: null,
            mid: null,
            
            orderNo: this.$route.query.orderNo,
            orderName: this.$route.query.orderName,
            paymentAmount: +(this.$route.query.paymentAmount || 0),
            buyerName: this.$route.query.buyerName,
            buyerPhone: this.$route.query.buyerPhone,
            buyerEmail: this.$route.query.buyerEmail,

            ymd: "",
            hash: "",
        };
    },
    mounted() {
	    window.addEventListener("message", (e) => {
            if(!e.data){
                window.parent?.korpayClose?.();
                window.opener?.korpayClose?.();
                window.close();
            }
            else{
                var div = document.createElement('div');
                div.setAttribute('id', 'sendData');
                document.body.appendChild(div);
                document.getElementById("sendData").innerHTML = e.data;
                document.transRslt.submit();
            }
        }, false);

        this.auth();
    },
    methods: {
        async auth() {
            const { paymentAmount } = this.$data;
            const { gateway, mid } = await api.plugins.korpay.config.get();
            const { ymd, hash } = await api.plugins.korpay.generate({ paymentAmount });

            this.gateway = gateway;
            this.mid = mid;
            this.ymd = ymd;
            this.hash = hash;

            this.$nextTick(() => {
                var form = this.$refs.form;

                if( navigator.userAgent.indexOf("Android") > - 1 || navigator.userAgent.indexOf("iPhone") > - 1 )
                {
                    // 모바일 환경일때 처리
                }
                // else{
                //     // PC 환경일때 처리
                //     var width = 500;
                //     var height = 477;
                //     var leftpos = screen.width / 2 - (width / 2);
                //     var toppos = screen.height / 2 - (height / 2);
                //     var feature = `width=${width}, height=${height}, toolbar=no, status=no, statusbar=no, menubar=no, scrollbars=no, resizable=no, left=${leftpos}, top=${toppos}`;

                //     var modal = window.open('about:blank', "coampayPopup", feature);
                //     if (modal == null) {
                //         //팝업 차단여부 확인
                //         alert("팝업이 차단되어 있습니다.\n팝업차단을 해제하신 뒤 다시 시도하여 주십시오.");
                //     }

                //     form.target = "coampayPopup";
                // }
                
                //인코딩 euc-kr 처리
                // if (/edge/.test(window.navigator.userAgent.toLowerCase()) || /MSIE/.test(window.navigator.userAgent) || /Trident.*rv[ :]*11\./.test(window.navigator.userAgent)) {
                //     var befCharset = document.charset;
                //     document.charset = "euc-kr";
                //     // window.document.charset = "utf-8";
                //     // document.charset = befCharset;
                // } else {
                //     this.$refs.form.acceptCharset = "euc-kr";
                //     // this.$refs.form.acceptCharset = "utf-8";
                // }

                form.action = gateway;
                form.method = "post";
                form.submit();

                // if (befCharset) document.charset = befCharset;
            });
        },
    },
    computed: {
        returnUrl() {
            return `${window.location.origin}/api/plugins/korpay/response`;
        },
        userAgent() {
            // return "WP"
            return (detect == "pc") ? "WP" : "WM";
        },
    },
};
</script>

<style scoped>
.progress{
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    top: 0;
    background: rgba(0,0,0,0.54);
}
.progress .spinner {
    position: fixed;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28px;
    width: 28px;
    animation: rotate 0.8s infinite linear;
    border: 8px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
}
@keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}
</style>